import "./App.css";
import Home from "./components/Home";
import { createBrowserRouter,RouterProvider } from "react-router-dom";
import NavBar from "./components/NavBar";
import ServicesPage from "./components/ServicesPage";
import ContactPage from "./components/Contact";
import RootLayOut from "./components/Root";
import AboutPage from "./components/About";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayOut />,
    // errorElement: <ErrorPage/>,
    children: [
      { index: true, element: <Home /> },
      { path: "about", element: <AboutPage /> },
      { path: "services", element: <ServicesPage /> },
      { path: "contacts", element: <ContactPage /> },
    ],
  },
  {
    path: "/services",
    element: <ServicesPage />
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
