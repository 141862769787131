import React, { useEffect } from "react";
import { mainServices } from "./ServicesDetails";
import OtherService from "./OtherServices";

const ServiceSection = ({ title, description, image, isImageRight }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div
      className={`flex flex-col md:flex-row items-center mb-8 rounded-lg shadow-xl border-4 border-gray-200 p-6   from-[#355C7D] via-[#6C5B7B] to-[#C06C84] text-white ${
        isImageRight
          ? "md:flex-row-reverse bg-gradient-to-r"
          : "bg-gradient-to-l"
      }`}
    >
      <div className="md:w-1/2 p-4 relative overflow-hidden group z-10">
        <img
          src={image}
          alt={title}
          className="w-full h-80 object-cover rounded-lg shadow-lg transition-transform duration-500 group-hover:scale-105 z-0"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-30 group-hover:opacity-50 transition-opacity duration-500"></div>
      </div>

      <div className="md:w-1/2 p-6">
        <h2 className="text-3xl font-bold mb-4 transition-colors duration-300 group-hover:text-yellow-400">
          {title}
        </h2>
        <p className="text-lg leading-relaxed">{description}</p>
      </div>
    </div>
  );
};

const ServicesPage = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto p-6 ">
        {mainServices.map((serviceCategory, categoryIndex) => (
          <div key={categoryIndex}>
            <h1 className="text-3xl font-bold mb-4">
              {serviceCategory.category}
            </h1>
            {serviceCategory.items.map((service, index) => (
              <ServiceSection
                key={index}
                title={service.title}
                description={service.description}
                image={service.image}
                isImageRight={index % 2 !== 0}
              />
            ))}
          </div>
        ))}
      </div>
      <OtherService />
    </>
  );
};

export default ServicesPage;
