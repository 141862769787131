import React from 'react';
import Icons from './Shared/Icons';


const FloatingContactIcons = () => {
  return (
    <div className="fixed bottom-6 right-6 flex flex-col space-y-4 z-50">
      <a
        href="https://wa.me/+971501169802"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition transform hover:scale-110"
      >
        <Icons name="whatsapp" color="white"  size={24} />
      </a>

      <a
        href="tel:+971 50 116 9802" 
        className="bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition transform hover:scale-110"
      >
        <Icons name="phone" color="white"  size={24} />
      </a>
    </div>
  );
};

export default FloatingContactIcons;
