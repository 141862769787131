import NavBar from "./NavBar";
import TopView from "./TopView";
import Services from "./Services";
import Testimonial from "./Testimonial";
import Footer from "./Footer";
import FloatingContact from "./FloatingContact";
import ServicesPage from "./ServicesPage";
import About from "./About";
import Contact from "./Contact";
import OtherService from "./OtherServices";


function Home() {
  return (
    <>
      {/* <NavBar /> */}
      <TopView />
      <Services />
      {/* <ServicesPage/> */}
      <Testimonial/>
      
    </>
  );
}

export default Home;
