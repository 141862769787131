import React, { useState, useEffect } from 'react';
import Icons from './Shared/Icons';

const Statistic = ({ title, target, duration,icon, iconSize }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const increment = target / (duration / 100); // Increment based on duration
    const timer = setInterval(() => {
      start += increment;
      if (start >= target) {
        setCount(target);
        clearInterval(timer);
      } else {
        setCount(Math.ceil(start));
      }
    }, 100);

    return () => clearInterval(timer);
  }, [target, duration]);

  return (
    <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md transition transform hover:scale-105 hover:shadow-xl">
      <Icons name={icon} color='blue' size={iconSize}  />
      <div className="md:text-5xl text-2xl font-bold text-blue-600">{count}</div>
      <div className="text-xl font-semibold text-gray-700 mt-2">{title}</div>
    </div>
  );
};

const StatisticsSection = () => {
  return (
    <div className="flex flex-row md:flex-col md:w-1/4 justify-around items-center text-center space-x-4 md:space-y-0 mt-2 md:mt-0 p-6 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-lg shadow-lg max-w-5xl">
      <Statistic title="Services Done" target={3000} duration={3000} icon='rating' iconSize={70} />
      <Statistic title="Happy Clients" target={2000} duration={3000} icon='happinessMan' iconSize={60} />
      <Statistic title="Our Expertise" target={500} duration={3000} icon='tickMan'  iconSize={65} />
    </div>
  );
};

export default StatisticsSection;
