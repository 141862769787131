import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export const Loader = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="w-10 h-10 border-4 border-blue-400 border-dashed rounded-full animate-spin"></div>
    </div>
  );
};

const ContactPage = () => {
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false);
  const [emailFailed, setSendFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm("service_i93i4jr", "template_4dby9ap", form.current, {
        publicKey: "111vISbMGsZ3IE-kj",
      })
      .then(
        () => {
          setEmailSent(true);
          setTimeout(() => {
            setEmailSent(false);
          }, 3000);
          setLoading(false);
          form.current.reset();
        },
        (error) => {
          setSendFailed(true);
          setTimeout(() => {
            setSendFailed(false);
          }, 3000);
          setLoading(false);
        }
      );
  };

  return (
    <div className="max-w-7xl mx-auto p-8 bg-gray-100">
      <h1 className="text-4xl font-bold text-center mb-12">Contact Us</h1>

      <div className="flex flex-col md:flex-row md:space-x-12">
        <div className="md:w-1/2 bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-2xl font-semibold mb-6">Get in Touch</h2>
          <form ref={form} onSubmit={sendEmail} className="space-y-4">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="user_name"
                required
                className="mt-1 p-3 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your Name"
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="user_email"
                required
                className="mt-1 p-3 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your Email"
              />
            </div>

            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                required
                className="mt-1 p-3 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your Message"
              ></textarea>
            </div>

            <button
              type="submit"
              value="Send"
              className="w-full bg-blue-600 text-white py-3 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300"
            >
              {loading ? <Loader /> : "Send Message"}
            </button>

            {emailSent && (
              <div className="text-green-500 text-center">Message Sent</div>
            )}
            {emailFailed && (
              <div className="text-red-500 text-center">Message not Sent</div>
            )}
          </form>
        </div>

        <div className="md:w-1/2 mt-12 md:mt-0 bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-2xl font-semibold mb-6">Our Location</h2>

          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-medium text-gray-700">Address</h3>
              <p className="text-gray-600">Hamdan Street, Abu Dhabi, U.A.E</p>
            </div>

            <div>
              <h3 className="text-lg font-medium text-gray-700">Phone</h3>
              <a href="tel:+971 50 116 9802" className="text-gray-600">
                +971 50 116 9802
              </a>{" "}
              <br />
              <a href="tel:+971 52 831 5482" className="text-gray-600">
                +971 52 831 5482
              </a>
            </div>

            <div>
              <h3 className="text-lg font-medium text-gray-700">Email</h3>
              <a
                href="mailto:planetfmcauh@gmail.com"
                className="text-gray-600 hover:underline"
              >
                planetfmcauh@gmail.com
              </a>
            </div>

            <div>
              <h3 className="text-lg font-medium text-gray-700">
                Working Hours
              </h3>
              <p className="text-gray-600">
                Sunday - Friday: 9:00 AM - 8:00 PM
              </p>
            </div>

            {/* Embedded Google Maps Location (optional) */}
            <div className="mt-8">
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3631.2765572051812!2d54.3453701!3d24.475872799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e655ce9556a71%3A0xd1f6741d62c10b27!2sAl%20Sawari%20Tower%20B!5e0!3m2!1sen!2suk!4v1674135816851!5m2!1sen!2suk"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
