import topImage from "../Images/top.jpg";
import Icons from "./Shared/Icons";
import Statistic from "./Statistic";
import { useNavigate } from "react-router-dom";

function ScrollDown() {
  const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight, // Scroll down to the next section
      behavior: "smooth",
    });
  };
  return (
    <div className="flex justify-center md:mt-14">
      <button
        onClick={handleScroll}
        className="animate-bounce bg-white text-black  font-semibold py-3 px-8 rounded-full shadow-lg hover:shadow-2xl focus:outline-none transition duration-300 transform hover:scale-110"
      >
        <Icons name="downArrow" color="black" />
      </button>
    </div>
  );
}

function TopImageText() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col md:flex-row items-center justify-around p-6 md:p-6 md:mt-24 mt-10">
      <div className="text-center md:text-left md:max-w-md space-y-6">
        <p className="text-3xl font-extrabold leading-tight">
          Cleaning with Passion, Shining with Pride
        </p>
        <p className="text-lg">
          We provide top-notch cleaning services to keep your space spotless and
          shining like new!
        </p>
        <button
          onClick={() => navigate("/contacts")}
          className="relative px-8 py-3 font-bold text-black hover:text-white bg-white rounded-lg overflow-hidden group"
        >
          <span className="absolute inset-0 w-full h-full bg-blue-700 transition-all duration-700 transform scale-x-0 group-hover:scale-x-100 origin-left"></span>
          <span className="relative z-10">Book A Service</span>
        </button>
      </div>

      <div className="mt-6 md:mt-0 md:ml-12">
        <img
          src={topImage}
          alt="trial"
          className="w-full md:w-80 rounded-lg shadow-2xl"
        />
      </div>
    </div>
  );
}
function TopView() {
  return (
    <>
      <div className="flex flex-col md:flex-row md:h-screen">
        <div className="flex flex-col bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-lg shadow-lg md:w-3/4">
          <TopImageText />
          <ScrollDown />
        </div>
        <Statistic />
      </div>
    </>
  );
}

export default TopView;
