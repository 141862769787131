import midImage from "../Images/midCleaning.jpg";
import kitchen from "../Images/kitchenCleaning.png";
import carpet from "../Images/carpetCleaning.png";
import window from "../Images/windowCleaning.png";
import rightArrow from "../Images/right-arrow.png";
import { useNavigate } from "react-router-dom";

export const ServiceCard = ({ title, description, icon, bgColor }) => {
  return (
    <div className={`flex items-center bg-white hover:bg-gray-800 hover:text-white  shadow-lg rounded-lg p-4 mb-4 transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl`}>
      <div
        className={`w-24 md:w-16 md:h-16 flex items-center justify-center rounded-full text-white ${bgColor} hover:bg-white mr-4 transform transition-transform duration-500 hover:rotate-12`}
      >
        <img src={icon} alt={title} className="w-8 h-8" />
      </div>

      <div>
        <h3 className="text-lg font-semibold">{title}</h3>
        <p >{description}</p>
      </div>
    </div>
  );
};

function SeeMore() {
  return (
    <div className="flex items-center justify-between bg-white shadow-lg rounded-lg p-4 mb-4 space-x-6 group hover:bg-blue-600 transition-all duration-500 ease-out transform hover:scale-105">
      <div>
        <h3 className="text-lg font-semibold text-gray-700 group-hover:text-white transition duration-500">
          See all our services
        </h3>
      </div>
      <div
        className={`w-12 h-12 flex items-center justify-center rounded-full bg-blue-500 group-hover:bg-white transition-all duration-500 ease-out transform group-hover:rotate-90`}
      >
        <img
          src={rightArrow}
          alt="none"
          className="w-6 h-6 group-hover:rotate-90 transition-transform duration-500 ease-out"
        />
      </div>
    </div>
  );
}
const ServicesSection = () => {
  const navigate = useNavigate();
  

  return (
    <div className="flex flex-col md:flex-row items-center justify-around max-w-6xl mx-auto p-6 bg-gray-50">
      <div className="md:w-1/2 text-left mb-6 md:mb-0">
        <p className="text-yellow-500 text-lg">Service</p>
        <h2 className="text-3xl font-bold leading-tight mb-4">
          The Services we provide for{" "}
          <span className="text-blue-600">our customer</span>
        </h2>
        <img
          src={midImage}
          alt="Cleaning supplies"
          className="w-full h-auto rounded-lg"
        />
      </div>

      <div className="w-full md:w-1/3 md:mt-32">
        <ServiceCard
          title="Window Cleaning"
          description="Make your windows shine"
          bgColor="bg-green-500"
          icon={window}
        />
        <ServiceCard
          title="Kitchen Cleaning"
          description="Keep your kitchen clean"
          bgColor="bg-blue-500"
          icon={kitchen}
        />
        <ServiceCard
          title="Carpet Cleaning"
          description="Keep your carpets spotless"
          bgColor="bg-yellow-500"
          icon={carpet}
        />
        <button className="w-full" onClick={() => navigate("/services")}>
          <SeeMore />
        </button>
      </div>
    </div>
  );
};

export default ServicesSection;
