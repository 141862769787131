import React, { useState } from "react";
import Icons from "./Shared/Icons";
import logo from "../Images/PlanetFMSLogo.png";
import { NavLink } from "react-router-dom";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  function resetHamburg() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <nav className="bg-white shadow-md p-4 z-50 relative">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-xl font-bold">
          <NavLink to="/">
            <img src={logo} alt="Logo" className="w-24 h-14" />
          </NavLink>
        </div>

        <div className="md:hidden">
          <button
            onClick={resetHamburg}
            className="flex justify-center text-gray-600 focus:outline-none"
          >
            <Icons
              name={isOpen ? "close" : "hamburgBlue"}
              size={isOpen ? 32 : 24}
              color="black"
            />
          </button>
        </div>

        <ul
          className={`${
            isOpen ? "block" : "hidden"
          } text-lg md:flex md:space-x-6 space-y-8 md:space-y-0 md:flex-row flex-col absolute top-16 left-0 h-auto w-full text-center md:static bg-white md:bg-transparent md:w-auto p-4 md:p-0`}
        >
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "text-blue-800 font-bold" : "text-gray-800"
              }
              onClick={resetHamburg}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive ? "text-blue-800 font-bold" : "text-gray-800"
              }
              onClick={resetHamburg}
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/services"
              className={({ isActive }) =>
                isActive ? "text-blue-800 font-bold" : "text-gray-800"
              }
              onClick={resetHamburg}
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contacts"
              className={({ isActive }) =>
                isActive ? "text-blue-800 font-bold" : "text-gray-800"
              }
              onClick={resetHamburg}
            >
              Contact
            </NavLink>
          </li>
        </ul>

        <div className="hidden md:flex items-center space-x-2 cursor-pointer">
          <Icons name="phone" size={24} color="green" />
          <a href="tel:+971 50 116 9802" className="text-gray-600 ">
            +971 501169802
          </a>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
