import React from "react";
import Icons from "./Shared/Icons";
import cleaningItems from "../Images/cleaningItems.png";
import cleaningItemsRight from "../Images/cleaningItemsRight.png";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-10 relative">
      {/* Social Icons */}
      <div className="flex justify-center space-x-6 mb-8 z-10 relative">
        <a
          href="https://www.instagram.com/planetfms/"
          className="text-gray-400 hover:text-green-500 transition"
        >
          <Icons name="instagram" color="white" size={24} />
        </a>
        <a
          href="https://www.facebook.com/people/Planetfms-Fms/pfbid02CTkbfQgohWXEr8NfP29VMXsdd664StstiGjMDCkpAPKhSDDbW9Powo1wPvQnQEoul/"
          className="text-gray-400 hover:text-green-500 transition"
        >
          <Icons name="facebook" color="white" size={24} />
        </a>
        <a
          href="https://wa.me/+971501169802" 
          className="text-gray-400 hover:text-green-500 transition"
        >
          <Icons name="whatsapp" color="white" size={24} />
        </a>
      </div>

      {/* Text Content */}
      <div className="text-center text-sm md:text-base z-10 relative">
        <p className="mb-4">© Planet FMS. ALL RIGHTS RESERVED</p>
        <p className="text-gray-400">
          DEVELOPED BY{" "}
          <a href="/"  className="text-green-500 hover:underline">
            Shaz Rasheed
          </a>
        </p>
      </div>

      {/* Background Image */}
      <div className="flex justify-between absolute bottom-0 left-0 right-0 z-0">
        <div className="w-1/2 md:w-1/4">
          <img
            src={cleaningItems} // Replace with the actual image path
            alt="Cleaning products"
            className="w-full h-auto"
          />
        </div>
        <div className="w-1/2 md:w-1/4">
          <img
            src={cleaningItemsRight} // Replace with the actual image path
            alt="Cleaning products"
            className="w-full h-auto"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
