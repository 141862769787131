import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";
import FloatingContact from "./FloatingContact";
function RootLayOut() {
  return (
    <>
      <NavBar />
      <main>
        <Outlet />
        <FloatingContact />
        <Footer />
      </main>
    </>
  );
}

export default RootLayOut;
