import React, { useState } from 'react';
import person from '../Images/person.png';

const testimonials = [
    {
      id: 1,
      name: 'Aisha M.',
      location: 'Dubai',
      feedback:
        'Exceptional service! The team was punctual, thorough, and left my house spotless. Highly recommend!',
    },
    {
      id: 2,
      name: 'David K.',
      location: 'Abu Dhabi',
      feedback:
        'The cleaners were professional and friendly. They did a fantastic job on my home, and I’ll definitely be using them again.',
    },
    {
      id: 3,
      name: 'Fatima A.',
      location: 'Sharjah',
      feedback:
        'I was amazed at the attention to detail. My office space has never looked better. Worth every penny!',
    },
    {
      id: 4,
      name: 'Grace O.',
      location: 'Ajman',
      feedback:
        'Great experience! The staff was polite, worked quickly, and left everything immaculate. Will book again.',
    },
    {
      id: 5,
      name: 'Ahmad S.',
      location: 'Fujairah',
      feedback:
        'Very satisfied with the cleaning service. They exceeded my expectations and left my home looking brand new!',
    },
  ];
  

const Testimonials = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const totalPages = Math.ceil(testimonials.length / 2);

  const handleNext = () => {
    setCurrentPage((prev) => (prev === totalPages - 1 ? 0 : prev + 1));
  };

  const handlePrevious = () => {
    setCurrentPage((prev) => (prev === 0 ? totalPages - 1 : prev - 1));
  };

  return (
    <div className="flex flex-col md:flex-row max-w-7xl mx-auto p-6 md:space-x-16">
      <div className="md:flex md:flex-col space-y-10 justify-between items-center mb-6 m-auto">
        <p className="md:text-7xl text-4xl font-bold md:text-center">WHAT THEY SAY</p>
        <div className="flex items-center justify-center">
          <button
            className="border border-gray-400 rounded-full p-2 mr-4 hover:bg-gray-100 transition duration-300"
            onClick={handlePrevious}
          >
            ←
          </button>
          <span className="text-lg">
            {currentPage + 1}/{totalPages}
          </span>
          <button
            className="border border-gray-400 rounded-full p-2 ml-4 hover:bg-gray-100 transition duration-300"
            onClick={handleNext}
          >
            →
          </button>
        </div>
      </div>

      <div className="grid grid-rows-1 md:grid-cols-2 gap-6 md:w-1/2">
        {testimonials
          .slice(currentPage * 2, currentPage * 2 + 2)
          .map((testimonial) => (
            <div
              key={testimonial.id}
              className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:scale-105 hover:bg-gray-800 hover:text-white"
            >
              <div className="flex items-start">
                <span className="text-green-600 text-3xl">“</span>
                <p className="ml-4 ">{testimonial.feedback}</p>
              </div>
              <div className="flex items-center mt-6">
                <img
                  src={person}
                  alt={testimonial.name}
                  className="w-12 h-12 rounded-full mr-4"
                />
                <div>
                  <h4 className="text-lg font-semibold">{testimonial.name}</h4>
                  <p className="text-sm ">{testimonial.location}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Testimonials;
