import window from "../Images/windowCleaning.png";
import babySitting from "../Images/Services/babysitting.jpg";
import maidServices from "../Images/Services/maidHD.jpg";
import ironingService from "../Images/Services/iron.jpg";
import laundryServices from "../Images/Services/laundry.jpeg";
import kitchenCleaning from "../Images/Services/kitchenHD.jpeg";
import partyCleaning from "../Images/Services/party.jpg";
import rodent from "../Images/Services/rodent.jpg";
import termite from "../Images/Services/termite.jpg";
import bedBugs from "../Images/Services/bed.jpg";
import bird from "../Images/Services/bird.jpg";
import flies from "../Images/Services/fly.jpg";
import cat from "../Images/Services/cat.jfif";
import deep from "../Images/Services/deepCleanHD.jpg"
import high from "../Images/Services/highrise.jpg";
import contractual from "../Images/Services/contractual.jpg";
import marble from "../Images/Services/marble.jpg";
import carpet from "../Images/Services/carpet.jpg";
import furniture from "../Images/Services/furniture.jpg";
import apartment from "../Images/Services/apartment.png";
import office from "../Images/Services/office.png";
import villa from "../Images/Services/villa.png";
import hospital from "../Images/Services/hospital.png";
import hotel from "../Images/Services/hotel.png";
import school from "../Images/Services/school.png";
import restaurent from "../Images/Services/restaurent.png";
import garbage from "../Images/Services/garbage.png";
import sand from "../Images/Services/sand.png";
import water from "../Images/Services/water.png";
import street from "../Images/Services/street.jpeg";
import waste from "../Images/Services/waste.png";
import facade from "../Images/Services/facade.jpeg";
import houseKeeping from "../Images/Services/housekeeping.png";
import container from "../Images/Services/container.jpeg";
import sewage from "../Images/Services/sewage.jpeg";
export const mainServices = [
  {
    category: "CLEANING SERVICES",
    items: [
      {
        title: "Deep Cleaning",
        description:
          "Deep cleaning is different from regular cleaning because it reaches the deep grime and dirt in your home. It covers areas that aren’t traditionally covered by a regular clean.",
        image: deep,
      },
      {
        title: "High Rise Cleaning",
        description:
          "We provide a high rise, completely self-contained, self-climbing window cleaning system for buildings that can clean up to maximum heights of windows in an hour.",
        image: high,
      },
      {
        title: "Contractual Cleaning Service",
        description:
          "We offer Contractual Cleaning Services Agreements between a home owner, office manager, or building management and a professional cleaning service provider.",
        bgColor: "bg-green-500",
        image: contractual,
      },
      {
        title: "Marble Restoration",
        description:
          "We have a professional marble restoration team that cleans and restores the shine of marbles in homes, offices, and business buildings across the UAE.",
        image: marble,
      },
      {
        title: "Carpet Cleaning & Shampooing",
        description:
          "We offer deep carpet cleaning and shampooing services at your doorstep. Our latest equipment ensures effective and thorough cleaning.",
        image: carpet,
      },
      {
        title: "Furniture Cleaning & Shampooing",
        description:
          "We provide residential and commercial upholstery, carpet, sofa, mattress cleaning, shampooing, and sanitizing services all over Dubai.",
        image: furniture,
      },
    ],
  },
  {
    category: "SPECIAL SERVICES",
    items: [
      {
        title: "Babysitting Services",
        description:
          "We take pride in offering professional babysitting services to families in UAE. Our team of babysitters are friendly, experienced, and simply love children. Our babysitting services offer complete childcare services.",
        image: babySitting,
      },
      {
        title: "Maid Services",
        description:
          "We are one of the trusted home maids cleaning companies in Abu Dhabi, specializing in delivering superior, meticulous, and efficient housekeeping services to homes and business offices. Our maids are reliable, trustworthy, and well-trained.",
        image: maidServices,
      },
      {
        title: "Ironing Service",
        description:
          "Do you hate ironing but are too fussy about being presentable? Let’s skip it with Planetfms laundry's very affordable Ironing package.",
        image: ironingService,
      },
      {
        title: "Laundry Services",
        description:
          "Deep cleaning is different from regular cleaning because it reaches the deep grime and dirt in your home. It covers areas that aren’t traditionally covered by a regular clean.",
        image: laundryServices,
      },
      {
        title: "Kitchen Cleaning",
        description:
          "Kitchens need the best cleaning services and here at Planetfms we provide the best for the same.",
        image: kitchenCleaning,
      },
      {
        title: "Party Helper/cleaner",
        description:
          "Our team of female domestic cleaners are well trained and professional so you can focus more on the party and have fun. We will clean the premises before and after the party and during the party, our maids will attend to visitor needs like serving food and beverages.",
        image: partyCleaning,
      },
    ],
  },
  {
    category: "PEST CONTROL",
    items: [
      {
        title: "Rodent Control",
        description:
          "All rodent removal methods are performed responsibly and professionally to achieve control and ultimately minimize risks to your business and residence.",
        image: rodent,
      },
      {
        title: "Termite Control",
        description:
          "We provide professional pest control services to residential, commercial, and industrial areas in Dubai.",
        image: termite,
      },
      {
        title: "Bed Bugs Control",
        description:
          "We offer effective and specialized spray treatments for permanent bedbug control in Dubai.",
        image: bedBugs,
      },
      {
        title: "Bird Control",
        description:
          "Birds Control, Best Experts and Municipality Approved Company - Call Now! Hassle Free Management, Excellent Work Quality, Perfect Execution and Reasonable Rates.",
        image: bird,
      },
      {
        title: "Flies Control",
        description:
          "Fly control services in Dubai. Best treatments and long-term solutions available for fly control in your home and workplace.",
        image: flies,
      },
      {
        title: "Cat Controlling Service",
        description:
          "Improve the life of a stray cat in the UAE. Give us a call and we will give the stray cats the best place.",
        image: cat,
      },
    ],
  },
];

export const interiorServicesCard = [
  {
    title: "Office Building Cleaning",
    description:
      "Professional cleaning services for office buildings ensuring a clean and productive work environment.",
    bgColor: "bg-blue-500",
    icon: office,
  },
  {
    title: "Residential Apartments",
    description:
      "Comprehensive cleaning services for residential apartments, keeping your living space spotless.",
    bgColor: "bg-yellow-500",
    icon: apartment,
  },
  {
    title: "Villa Cleaning",
    description:
      "High-quality villa cleaning services tailored to meet the needs of luxury homes.",
    bgColor: "bg-red-500",
    icon: villa,
  },
  {
    title: "Hospital Cleaning",
    description:
      "Specialized cleaning services for hospitals, ensuring a hygienic and safe environment.",
    bgColor: "bg-purple-500",
    icon: hospital,
  },
  {
    title: "Hotel & Furnished",
    description:
      "Expert housekeeping and cleaning services for hotels and furnished accommodations.",
    bgColor: "bg-teal-500",
    icon: hotel,
  },
  {
    title: "Apartments Housekeeping Services",
    description:
      "Top-notch housekeeping services for apartments, maintaining cleanliness and comfort.",
    bgColor: "bg-orange-500",
    icon: houseKeeping,
  },
  {
    title: "Restaurants Stewarding",
    description:
      "Efficient and reliable cleaning and stewarding services for restaurants.",
    bgColor: "bg-pink-500",
    icon: restaurent,
  },
  {
    title: "Plant & Factory Cleaning",
    description:
      "Industrial cleaning services designed for plants and factories, ensuring safe operations.",
    bgColor: "bg-green-500",
    icon: office,
  },
  {
    title: "School Universities Cleaning",
    description:
      "Thorough cleaning services for schools and universities, creating a healthy learning environment.",
    bgColor: "bg-indigo-500",
    icon: school,
  },
];

export const exteriorServicesCard = [
  {
    title: "Building Facade Cleaning",
    description:
      "Professional cleaning services for building facades to enhance their appearance and longevity.",
    bgColor: "bg-blue-600",
    icon: facade,
  },
  {
    title: "Water Blast Cleaning",
    description:
      "High-pressure water blast cleaning for tough exterior surfaces, removing dirt and grime effectively.",
    bgColor: "bg-red-600",
    icon: water,
  },
  {
    title: "Sand Blast Cleaning",
    description:
      "Specialized sandblast cleaning for exterior surfaces, ensuring thorough removal of debris.",
    bgColor: "bg-yellow-600",
    icon: sand,
  },
  {
    title: "Window Cleaning Using Access System",
    description:
      "Safe and efficient window cleaning services using advanced access systems for high-rise buildings.",
    bgColor: "bg-green-600",
    icon: window,
  },
  {
    title: "Waste Management",
    description:
      "Comprehensive waste management services for residential and commercial properties.",
    bgColor: "bg-purple-600",
    icon: waste,
  },
  {
    title: "Street Sweeping and Cleaning",
    description:
      "Effective street sweeping and cleaning services to keep public spaces clean and orderly.",
    bgColor: "bg-teal-600",
    icon: street,
  },
  {
    title: "Garbage Containers Removal",
    description:
      "Efficient garbage container removal services to keep your property clean and waste-free.",
    bgColor: "bg-orange-600",
    icon: container,
  },
  {
    title: "Garbage Collection from Residential",
    description:
      "Reliable garbage collection services for residential areas, ensuring timely waste removal.",
    bgColor: "bg-pink-600",
    icon: garbage,
  },
  {
    title: "Sewage and Manhole Cleaning",
    description:
      "Specialized cleaning services for sewage systems and manholes to prevent blockages.",
    bgColor: "bg-indigo-600",
    icon: sewage,
  },
];
