import { ServiceCard } from "./Services";
import { interiorServicesCard, exteriorServicesCard } from "./ServicesDetails";


function OtherService() {
  return (
    <>
      <div className="flex md:flex-row flex-col justify-between md:p-14 p-10 md:space-x-14">
        <div className="md:w-1/2">
          <div className="text-3xl font-bold mb-4 md:mb-8">Interior cleaning</div>
          {interiorServicesCard.map((card) => {
            return (
              <ServiceCard
                title={card.title}
                description={card.description}
                bgColor={card.bgColor}
                icon={card.icon}
              />
            );
          })}
        </div>
        <div className="md:w-1/2">
          <div className="text-3xl font-bold mb-4 md:mb-8">Exterior cleaning</div>
          {exteriorServicesCard.map((card) => {
            return (
              <ServiceCard
                title={card.title}
                description={card.description}
                bgColor={card.bgColor}
                icon={card.icon}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default OtherService;
