import React from 'react';
import card from '../Images/card.jpeg';

const AboutPage = () => {
  return (
    <div className="max-w-7xl mx-auto p-8 bg-gray-50">
      <section className="mb-12">
        <h1 className="text-4xl font-bold text-blue-600 mb-4">About Our Work</h1>
        <p className="text-lg text-gray-700 mb-4">
          PLANET FACILITIES MANAGEMENT SERVICES (Planet FMS) is a company based in Abu Dhabi that delivers unique integrated facilities management solutions to clients involved in different industries such as Oil & Gas, Retail, Government, Defense, Education, Healthcare, and Residential/Property. The company includes operational expertise in supporting remote, urban, as well as offshore sites.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          The broad range of support services offered by the company contributes to Abu Dhabi's vision of creating a truly global city for trade and commerce. Planet FMS has a specialized Learning & Development function that focuses on delivering the best training programs for the existing and new employees. The company's team is highly skilled and dedicated to providing quality services to all clients. Planet FMS offers excellent growth, learning, and development opportunities to all its employees.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          Our team of highly experienced professionals and quality engineers are the strength of the company. The entire team, being stakeholders, is focused on growth and maintaining the goodwill of the company with prime importance given to customers and clients.
        </p>

        <img src={card} alt=''/>
      </section>



      <section className="mb-12">
        <h2 className="text-3xl font-bold text-green-600 mb-4">Our Vision</h2>
        <p className="text-lg text-gray-700">
          To be the Top Performing and Most Reckoned in Building Renovations & Maintenance in the United Arab Emirates.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold text-indigo-600 mb-4">Our Mission</h2>
        <ul className="list-disc list-inside text-lg text-gray-700 space-y-2">
          <li>Renovation Maintenance and services that satisfy the needs of our customers.</li>
          <li>Constantly achieving operational excellence.</li>
          <li>Conducting our business in a safe, environmentally sustainable, and economically optimum manner.</li>
          <li>Employing a diverse, innovative, and results-oriented team motivated to deliver excellence.</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold text-red-600 mb-4">Our Values</h2>
        <p className="text-lg text-gray-700 mb-4">
          At Planet FMS, we believe that each project is the single greatest vehicle for knowledge, understanding, and human advancement that the world has ever known. The great task of delivering a new project hinges on the creativity and commitment of our people.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          The Planet FMS Way reflects a commitment to every Planet FMS employee that the culture and company inspire their best work—their life's work.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          Together, we can connect everything and empower everyone.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold text-yellow-600 mb-4">Our Objectives</h2>
        <ul className="list-disc list-inside text-lg text-gray-700 space-y-2">
          <li>Minimize the Customers' budget.</li>
          <li>Realize the customer requirements.</li>
          <li>Meet our customer satisfaction.</li>
          <li>Job Guarantee.</li>
          <li>Safeguard customer job integrity.</li>
          <li>Deliver structural cost reductions.</li>
          <li>Sustain a robust management system.</li>
          <li>Deliver continuous sustainable HSE (Health, Safety, Security, and Environmental excellence).</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold text-teal-600 mb-4">We Are About Trust</h2>
        <p className="text-lg text-gray-700 mb-4">
          We inspire confidence in colleagues, customers, and partners by always acting with integrity, fairness, respect, and reliability.
        </p>
        <h2 className="text-3xl font-bold text-orange-600 mb-4">We Deliver Excellence</h2>
        <p className="text-lg text-gray-700 mb-4">
          We are driven by our craft. We pride ourselves on executional excellence, measurably delivering beyond expectations. Our work ethic seeks excellence as the goal, and we believe that disciplined repeated effort, with input from all sides, yields the best results.
        </p>
      </section>

      <section>
        <h2 className="text-3xl font-bold text-purple-600 mb-4">Suppliers</h2>
        <p className="text-lg text-gray-700">
          At Planet FMS, we only deal with registered vendors and suppliers to ensure the quality of services is maintained and we don't face any delivery issues. Stock levels for required chemicals are maintained at all times and are managed and monitored by our Store Manager. All inventory items are managed using Inflow Inventory Management Software to monitor stock levels across all projects.
        </p>
      </section>
    </div>
  );
};

export default AboutPage;
